<div>
  <h1 class="text-title-4 mb-8">
    {{ 'rogers.setPhoneNumber.header' | translate }}
  </h1>
  <p class="text-body-lg mb-8" translate>{{ setPhoneNumbermessage }}
  </p>

  <div class="mb-16" *ngIf="error === 'tooManyAttemptsOrExpired'">
   <ds-alert
     variant="error"
     [closeLabel]="'rogers.login.popOverClose' | translate"
     (closeClick)="resetErrorMessage()"
     role="alert"
     ssTrackError
     [error]="track.changeMobileInputErrors">
     <div class="d-flex flex-column flex-md-row">
       <div class="flex-grow-1 d-flex">
         <ds-icon
           class="mr-8"
           name="exclamation-circle"
           color="error"
           [ariaHidden]="false"
           [attr.title]="'rogers.login.accessibility.error' | translate"
           caption="Attention">
         </ds-icon>
         <div>
           <p damTranslationWithRouting
             class="text-body mb-0 mw-content">
             {{ 'rogers.verifySms.error.tooManyAttemptsOrExpired' | translate }}
           </p>
         </div>
       </div>
     </div>
   </ds-alert>
 </div>

 <div class="mb-16" *ngIf="error === 'UE09'">
  <ds-alert
    variant="error"
    [closeLabel]="'rogers.login.popOverClose' | translate"
    (closeClick)="resetErrorMessage()"
    role="alert"
    ssTrackError
    [error]="track.changeMobileInputErrors">
    <div class="d-flex flex-column flex-md-row">
      <div class="flex-grow-1 d-flex">
        <ds-icon
          class="mr-8"
          name="exclamation-circle"
          color="error"
          [ariaHidden]="false"
          [attr.title]="'rogers.login.accessibility.error' | translate"
          caption="Attention">
        </ds-icon>
        <div>
          <p damTranslationWithRouting
            class="text-body mb-0 mw-content">
            {{ 'rogers.verifySms.error.UE09' | translate }}
          </p>
        </div>
      </div>
    </div>
  </ds-alert>
</div>

  <form [formGroup]="phoneNumberForm" (ngSubmit)="onSubmit()"
  autocomplete="off" id="phoneNumberForm">
    <ng-container ssTrackEvent [event]="track.changeMobileInputPageview"></ng-container>
  <div class="row">
     <div class="col-12 mt-16 mb-32">
        <ds-form-field>
           <ds-input-label>{{
              'rogers.setPhoneNumber.phoneNumber' | translate
              }}
           </ds-input-label>
           <input
           dsInput
           formControlName="phoneNumber"
           [dsMask]="InputMaskType.PhoneNumber"
           type="text"
           #usernameInputPhoneNumber
           (keydown.enter)="keyDownEnter($event)"
           (keydown.space)="$event.preventDefault()"
           [placeholder]="InputMaskType.PhoneNumber"
           [attr.aria-required]="true"
           [attr.aria-invalid]="false"
           [attr.aria-label]="
           'rogers.changeUsername.confirmReoveryNumber.mobile'| translate "
           [attr.title]="'rogers.changeUsername.confirmReoveryNumber.mobile'| translate "
           />
           <ds-error
           [errorMessage]="errorMessage | translate"
           *ngIf="
           isFormSubmitted &&
           phoneNumberForm.invalid &&
           phoneNumberForm.get('phoneNumber').invalid
           "
           role="alert"
           alt="Attention"
           ssTrackError
           [error]="track.changeMobileInputFormError"
           ></ds-error>
        </ds-form-field>
     </div>
  </div>
  <div class="mb-16">
    <p class="text-body mb-0 mw-content">{{ 'rogers.setPhoneNumber.middleText' | translate }}</p>
 </div>
  <div>
     <button
        type="submit"
        ds-button
        class="w-100 mb-16"
        title="{{ 'rogers.setPhoneNumber.continueAlt' | translate }}"
        #continueBtn
        >
        <span *ngIf="!setRecoveryNumberInProgress">{{
        'rogers.setPhoneNumber.btnContinue' | translate
        }}</span>
        <eas-loader class="eas-loader" *ngIf="setRecoveryNumberInProgress"></eas-loader>
     </button>

     <button *ngIf="isIframe"
     ds-button
     class="w-100 mb-16"
     [attr.title]="'rogers.setPhoneNumber.closeAlt' | translate"
     variant="secondary"
     (click)="onRecoveryNumberChangeSkip($event)"
     >
     {{ 'rogers.setPhoneNumber.btnClose' | translate }}
     </button>
     <button *ngIf="!isIframe"
     ds-button
     class="w-100 mb-16"
     [attr.title]="'rogers.setPhoneNumber.closeAlt' | translate"
     variant="secondary"
     (click)="onRecoveryNumberChangeSkip($event)"
     >
     {{ 'rogers.setPhoneNumber.btnSkip' | translate }}
     </button>

  </div>
  </form>

</div>
