import {Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { InputMaskType } from '@rogers/cdk/form';
import IMask from 'imask/esm/imask';
import {AccordionPanel} from '@rogers/cdk/accordion';
import {
  ModalComponent as DsModalComponent,
  ModalRef,
  ModalService,
} from '@rogers/cdk/modal';
import { ConfigurationService } from '../shared/configuration.service';
import { UserMonitoringService } from 'src/app/shared/user-monitoring.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-username-form-no-recaptcha',
  templateUrl: './username-form-no-recaptcha.component.html',
})
export class UsernameFormNoRecaptchaComponent implements OnInit {
  constructor(
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly configurationService: ConfigurationService,
    private readonly usm: UserMonitoringService,
    private readonly cookieService: CookieService
  ) {

    const flow = this.cookieService.get('flow');
    if (flow === 'username'){
      this.loginStore.flowBranch = this.flowBranchEnum.username;
    }
    else{
      this.loginStore.flowBranch = this.flowBranchEnum.login;
    }

    this.cookieService.delete('flow');
    console.log('enum value from function: ', this.getEnumKeyByEnumValue(this.flowBranchEnum, 'create'));
  }

  onSubmitUserId: (payload: object) => void;
  onForgotUserEmailSubmit: (payload: object) => void;
  onRegisterClick: (code: string) => void;
  onForgotUsernameAccountClick: () => void;
  onVerifyUserEmailSubmit: (payload: object) => void;

  readonly flowBranchEnum = FlowBranchEnum;
  loginStore = new LoginStoreModel();
  rememberMeFlagValue = false;
  rememberMeUser: string;
  payload: object;
  flowFromPayload: string;

  @ViewChild('username') usernameField: ElementRef;
  @ViewChild('emailUsername') emailUsernameField: ElementRef;
  @ViewChild('emailCreate') emailCreateField: ElementRef;
  @ViewChild('submitLogin') submitLoginButton: ElementRef;
  @ViewChild('submitCreate') submitCreateButton: ElementRef;
  @ViewChild('submitUsername') submitUsernameButton: ElementRef;
  @ViewChild('submitProfile') submitProfileButton: ElementRef;
  @ViewChild('account') accountField: ElementRef;
  @ViewChild('changeusernameModal') public templateRef: TemplateRef<DsModalComponent>;
  @ViewChild('usernameInput', { static: true })

  currentLang: string;
  dobMask: any;
  readonly inputMaskType = InputMaskType;
  submitted: boolean;


  loginStart = false;
  loginForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  headerHolder: string;
  footerHolder: string;
  footerRegister: string;
  loginTip: string;
  recoverUsernameTip: string;
  client: string | null;
  userEmail: string;
  apiError = false;
  forgotUsernameByEmailDesc: string;

  hideForgotUsername: boolean;

  registerForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl('' , [
        Validators.required,
        Validators.email
      ])
    },
    { updateOn: 'submit' }
  );

  accountNumberMask = '000000000000';
  accountNumberRegex = '^(?=[0-9]*$)(?:.{9}|.{12})$';
  postalCodeRegex = '^[A-Za-z]\\d[A-Za-z]\\d[A-Za-z]\\d$';
  dateRegex = '^\\d{4}\\/\\d{2}\\/\\d{2}$';

  modalRef: ModalRef;
  accountModalAccordion: Array<AccordionPanel>;

  accountForm = new UntypedFormGroup(
    {
      accountNumber: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.accountNumberRegex)
      ]),
      postalCode: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.postalCodeRegex)
      ]),
      dob: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.dateRegex)
      ])
    }
  );

  color = 'white';
  variant = 'regular';
  size = 'md';
  brand1 = 'rogers';
  loginInProgress = false;
  verifyEmailInProgress = false;
  usernameInProgress = false;

  track = {
    loginUsernameInputPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'username-input',
      additionalPayload: {
        miscellaneous: {
          type: 'username-remembered',
          name: this.cookieService.get('rememberMe') ? 'yes' : 'no'
        }
      }
    },
    usernameInputErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: '',
      category: '',
      type: 'http',
      code: '',
      API: 'https://rogers-dev.transmit.security/api/v2/auth/assert',
      field: ''
    },
    loginUsernameInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'username-input',
      category: 'login',
      type: 'form',
      code: 'ECA1',
      API: '',
      field: 'username'
    },
    findUsernameWithEmailPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'find-username-with-email'
    },
    recoveryUsernameInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'find-username-with-email',
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: 'username'
    },
  };

  private static isEnglishOrFrench(languageToCheck) {
    const path = window.location.href;
    if (
      path.indexOf(`/${languageToCheck}`) > -1 ||
      path.indexOf(`lang=${languageToCheck}`) > -1 ||
      path.indexOf(`setLanguage=${languageToCheck}`) > -1
    ) {
      return languageToCheck;
    }
    return '';
  }

  private static determineLanguage() {
    let language = UsernameFormNoRecaptchaComponent.isEnglishOrFrench('en');
    if (!language) {
      language = UsernameFormNoRecaptchaComponent.isEnglishOrFrench('fr');
    }
    language = language || 'en';
    return language;
  }

  ngOnInit() {

    this.loginStart = true;
    this.cookieService.set('analytics', '',undefined, undefined, null, true, 'None');
    const element = document.getElementById('transmitContainer');
    element.classList.add('hideTransmitContainer');

    this.setTranslatePropertiesByBrand();

    if (this.client === 'r4b' || this.client === 'yahoo'){
      this.hideForgotUsername = true;
    }
    this.currentLang = this.cookieService.get('lang');
    console.log('currentLang: ', this.currentLang);


    console.log('flowFromPayload: ', this.flowFromPayload);
    console.log('loginStoreErrorCode: ', this.cookieService.get('loginStoreErrorCode'));

    this.loginStore.errorCode = this.cookieService.get('loginStoreErrorCode');
    this.cookieService.set('loginStoreErrorCode', '',undefined, undefined, null, true, 'None');

    if (this.loginStore.errorCode === 'UE05'){
      this.loginStore.flowBranch = this.flowBranchEnum.login;
      this.userEmail = this.cookieService.get('userEmail');
    }

    if (this.loginStore.errorCode === 'UE01'){
      this.userEmail = this.cookieService.get('userEmail');
    }
    this.dobMask = {
      blocks: {
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          minLength: 2,
          maxLength: 2,
          placeholderChar: 'M'
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          minLength: 2,
          maxLength: 2,
          placeholderChar: this.currentLang === 'en' ? 'D' : 'J'
        },
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: new Date().getFullYear(),
          minLength: 4,
          maxLength: 4,
          placeholderChar: this.currentLang === 'en' ? 'Y' : 'A'
        }
      },
      mask: 'YYYY/MM/DD'
    } as any;

    this.accountModalAccordion = [
      {
        header: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.billIsReady'
        ),
        expandedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.billIsReadyExpandedAlt'
        ),
        collapsedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.billIsReadyCollapsedAlt'
        ),
        expanded: false
      },
      {
        header: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.serviceAgreement'
        ),
        expandedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.serviceAgreementExpandedAlt'
        ),
        collapsedAlt: this.translate.instant(
          'rogers.forgotUsername.whereToFindModal.serviceAgreementCollapsedAlt'
        ),
        expanded: false
      }
    ];

    console.log('oninit called');

    this.setTitle();

    this.creatForgotUsernameForm();
    this.createUsernameForm();

    this.setAnalyticsError(
      this.loginStore.errorCode,
      this.loginStore.flowBranch
    );

    setTimeout(() => {

      if (this.loginStore.flowBranch === this.flowBranchEnum.username){
        this.emailUsernameField.nativeElement.focus();
      }
      else{
        this.loginForm.get('username').reset(this.rememberMeUser);
        this.loginForm.get('rememberMe').reset(this.rememberMeFlagValue);
        if (this.loginStore.errorCode !== 'UE05'){
          this.usernameField.nativeElement.focus();
        }
      }

    }, 500);
  }


  setTitle() {
    let title = '';
    const brand = this.cookieService.get('brand');

    if (this.loginStore.flowBranch === this.flowBranchEnum.login && this.loginStore.errorCode !== 'UE05')
    {
      title = brand + '.login.browserTitle';
    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.login && this.loginStore.errorCode === 'UE05')
    {
      title = brand + '.forgotUsername.browserTitle';
    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.create)
    {
      title = brand + '.login.browserTitle';

    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.username)
    {
      title = brand + '.forgotUsername.browserTitle';

    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.profile)
    {
      title = brand + '.forgotUsername.browserTitle';
    }
    else {
      title = brand + '.login.browserTitle';
    }

    this.titleService.setTitle(
      this.translate.instant(title)
    );

    this.translate.onLangChange
      .subscribe((langEvent: LangChangeEvent) => {
        this.titleService.setTitle(
          this.translate.instant(title)
        );
        this.currentLang = langEvent.lang;
      });
  }


  createUsernameForm(){
    const rememberFlag = this.cookieService.get('rememberMe');
    this.rememberMeUser = this.cookieService.get('username'); // env50@mailinator.com
    this.rememberMeFlagValue = !(rememberFlag === 'false');
    this.loginForm = new UntypedFormGroup(
      {
        username: new UntypedFormControl(this.rememberMeUser, [Validators.required]),
        rememberMe: new UntypedFormControl(this.rememberMeFlagValue, [])
      },
      { updateOn: 'submit' }
    );
  }

  creatForgotUsernameForm(){
    const emailRegex = this.configurationService.config.emailValidationPattern;
    this.emailForm = new UntypedFormGroup(
      {
        email: new UntypedFormControl('' , [Validators.required, Validators.pattern(emailRegex)])
      },
      { updateOn: 'submit' }
    );
  }

  validateAndSubmit() {
    console.log('UserFormSession - OnSubmit');
    console.log('Passing username: ', this.loginForm.value.username);
    console.log('rememberMe', this.loginForm.value.rememberMe);

    // set uu username on dynatrace
    this.usm.identifyUser(this.loginForm.value.username);

    this.submitted = true;
    this.resetErrorCode();

    if (this.loginStore.flowBranch === this.flowBranchEnum.username){
        this.onForgotUsernameSubmit();
      }
      else{
        this.postSubmit();
      }
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    switch (this.loginStore.flowBranch) {
      case this.flowBranchEnum.login: {
        this.submitLoginButton.nativeElement.click();
        break;
      }
      case this.flowBranchEnum.username: {
        this.submitUsernameButton.nativeElement.click();
        break;
      }
      case this.flowBranchEnum.create: {
        this.submitCreateButton.nativeElement.click();
        break;
      }
      case this.flowBranchEnum.profile: {
        this.submitProfileButton.nativeElement.click();
        break;
      }
    }

  }

  resetErrorCode() {
    this.loginStore.errorCode = '';
  }


  routeLink(flow: FlowBranchEnum) {
    this.resetErrorCode();
    this.loginStore.flowBranch = flow;
    setTimeout(() => {
      switch (this.loginStore.flowBranch) {
        case this.flowBranchEnum.login: {
          this.emailForm.reset();
          this.usernameField.nativeElement.focus();
          this.cookieService.delete('flow');
          this.setTitle();
          break;
        }
        case this.flowBranchEnum.username: {
          this.emailForm.reset();
          this.cookieService.set('title', 'forgotusername',undefined, undefined, null, true, 'None');
          this.emailUsernameField.nativeElement.focus();
          this.setTitle();

          break;
        }
        case this.flowBranchEnum.create: {
          this.emailCreateField.nativeElement.focus();
          break;
        }
        case this.flowBranchEnum.profile: {
          this.accountField.nativeElement.focus();
          break;
        }
      }
    }, 500);
  }

  onPrepaid(){
    window.location.href = 'https://www.rogers.com/web/totes/#/sso/registration';
  }

  openModal(templateRef: TemplateRef<DsModalComponent>) {
    this.modalRef = this.modalService.open(
      templateRef,
      this.viewContainerRef,
      { backdropClick: false }
    );
  }

  closeModal() {
    this.modalRef.close();
  }

  modalCloseButtonClick(event: Event) {
    // console.log('Modal close button clicked', event);
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }


  processRequest() {
    const isVerifyEmail =  this.cookieService.get('verifyEmail');

    if (isVerifyEmail === 'true'){
      this.verifyEmailInProgress = true;
      console.log('verify email remember me : ', this.loginForm.value.rememberMe);
      this.onVerifyUserEmailSubmit({
        action: 'submit',
        username: this.cookieService.get('userEmail'),
        flow: 'create',
        language: this.cookieService.get('lang'),
        rememberme: this.cookieService.get('rememberMe')
      });
    }
    else if (this.loginStore.flowBranch === this.flowBranchEnum.username){
      this.onForgotUsernameSubmit();
    }
    else{
      this.postSubmit();
    }
  }

  private checkInProgress() {
    this.loginInProgress = true;

  }

  private checkTransmitContainerError () {
    const transmitContainerHTML = document.getElementById("transmitContainer").innerHTML;
    const str = 'Error';
    const errorFound = transmitContainerHTML.includes(str);
    console.log('transmitContainerHTML ', transmitContainerHTML);


    if (transmitContainerHTML.indexOf(str) !== -1){
      console.error('Transmit Error');
      const continueBtn: HTMLElement = document.querySelectorAll("button.xmui-button")[0] as HTMLElement;
      // console.error('continueBtn ', continueBtn);
      continueBtn.click();
    }
  }

  //  check if we have locked user dialog box in dom

  private checkUserLocked() {
    const transmitContainerHTML = document.getElementById('transmitContainer').innerHTML;
    const str = 'User Locked';
    const lockedFound = transmitContainerHTML.includes(str);

    if (transmitContainerHTML.indexOf(str) !== -1){
      console.error('User is locked by Transmit');
      const continueBtn: HTMLElement = document.querySelectorAll("button.xmui-button")[0] as HTMLElement;
      continueBtn.click();
    }
    else {
      console.error('User is NOT locked by Transmit');
      this.loginInProgress = false;

    }
  }


  private postSubmit() {
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    const element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    setTimeout(this.checkTransmitContainerError, 1500);
    setTimeout(this.checkUserLocked, 1600);

    if (username !== null && password !== null) {
    if (!this.loginForm.invalid) {

      this.loginInProgress = true;
      if (this.loginForm.value.rememberMe) {
        this.cookieService.set('username', this.loginForm.value.username,undefined, undefined, null, true, 'None');
        this.cookieService.set('rememberMe', this.loginForm.value.rememberMe,undefined, undefined, null, true, 'None');
      } else {
        this.cookieService.delete('username');
        this.cookieService.delete('rememberMe');
        this.rememberMeFlagValue = false;
      }

      this.cookieService.set('userEmail', this.loginForm.value.username,undefined, undefined, null, true, 'None');

      console.log('remember me : ', this.loginForm.value.rememberMe);
      this.onSubmitUserId({
        action: 'submit',
        username: this.loginForm.value.username,
        flow: 'login',
        language: this.cookieService.get('lang'),
        rememberme: this.loginForm.value.rememberMe
      });
    } else {
      console.log('validateAndSubmit - form is invalid');
      return;
     }
    }
    else {
      this.validateAndSubmit();
    }
  }

  private onForgotUsernameSubmit() {
    const userEmail = this.emailForm.value.email;
    this.cookieService.set('userEmail', userEmail,undefined, undefined, null, true, 'None');

    if (userEmail !== null) {
    if (!this.emailForm.invalid) {

      this.usernameInProgress = true;
      this.onForgotUserEmailSubmit({
        action: 'submit',
        username: this.emailForm.value.email,
        flow: 'password',
        language: this.cookieService.get('lang')
      });
    }
    else {
      console.log('forgot username email form is invalid');
      return;
     }
    }
  }

  onVerifyEmail() {
    this.cookieService.set('verifyEmail', 'true',undefined, undefined, null, true, 'None');

    this.processRequest();

    // check transmit hidden errors in dom
    const element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    setTimeout(this.checkTransmitContainerError, 1500);
    setTimeout(this.checkUserLocked, 1600);
  }


  setErrorCode() {
    this.loginInProgress = false;
    this.apiError = true;
  }

  outsideClickCapture() {
    this.loginStart = false;
  }

  onRegisterClickSubmit(){
    const brand = this.cookieService.get('brand');

    this.cookieService.set('title', 'register',undefined, undefined, null, true, 'None');
    this.onRegisterClick(brand);
    // this.loginStore.flowBranch = this.flowBranchEnum.create;
  }

  onForgotUsernameAccountClickSubmit(){
    this.onForgotUsernameAccountClick();
  }

  private setTranslatePropertiesByBrand(){
    this.client = this.cookieService.get('client');
    // common
    this.footerRegister = 'rogers.login.register';
    this.loginTip = 'rogers.login.tip';
    this.recoverUsernameTip = 'rogers.login.recoverUsernameTip';

    // Overrides below
    if (this.client === 'media'){
      this.headerHolder = 'media.login.header';
      this.footerHolder = 'media.login.footer';
      this.forgotUsernameByEmailDesc = 'media.forgotUsername.description.byEmail';
    }
    else if (this.client === 'fido') {
      this.headerHolder = 'fido.login.header';
      this.footerHolder = 'fido.login.footer';
      this.forgotUsernameByEmailDesc = 'fido.forgotUsername.description.byEmail';
      this.loginTip = 'fido.login.tip';
      this.recoverUsernameTip = 'fido.login.recoverUsernameTip';

    }
    else if (this.client === 'r4b') {
      this.headerHolder = 'r4b.login.header';
      this.footerHolder = 'r4b.login.footer';
      this.forgotUsernameByEmailDesc = 'rogers.forgotUsername.description.byEmail';
      this.footerRegister = 'r4b.login.register';
      this.loginTip = 'r4b.login.tip';
      this.recoverUsernameTip = 'r4b.login.recoverUsernameTip';

    }
    else if (this.client === 'yahoo') {
      this.headerHolder = 'yahoo.login.header';
      this.footerHolder = 'yahoo.login.footer';
      this.forgotUsernameByEmailDesc = 'yahoo.forgotUsername.description.byEmail';
      this.footerRegister = 'yahoo.login.register';
      this.loginTip = 'yahoo.login.tip';
      this.recoverUsernameTip = 'yahoo.login.recoverUsernameTip';

    }
    else {
      this.headerHolder = 'rogers.login.header';
      this.footerHolder = 'rogers.login.footer';
      this.forgotUsernameByEmailDesc = 'rogers.forgotUsername.description.byEmail';
    }
  }

  onBackClick(flow: FlowBranchEnum){

  }

  setAnalyticsError(code: string, flow: FlowBranchEnum) {
    if (!code) {
      return;
    }
    switch (flow) {
      case this.flowBranchEnum.login:
        this.track.usernameInputErrors.category = 'login';
        this.track.usernameInputErrors.mappingKey = 'username-input';
        break;
      case this.flowBranchEnum.username:
        this.track.usernameInputErrors.category = 'self serve';
        this.track.usernameInputErrors.mappingKey = 'find-username-with-email';
        break;
      default:
        this.track.usernameInputErrors.category = 'self serve';
        this.track.usernameInputErrors.mappingKey = 'find-username-with-email';
    }
    this.track.usernameInputErrors.code = code;
    if (code === 'UE05') {
      this.track.usernameInputErrors.pageView = true;
      this.track.usernameInputErrors.mappingKey = 'email-already-registered';
      this.track.usernameInputErrors.type = 'user';
    } else if (code === 'SE01') {
      this.track.usernameInputErrors.type = 'http';
    } else if (code.indexOf('UE') >= 0) {
      this.track.usernameInputErrors.type = 'user';
    }
  }
}

export class LoginStoreModel {
  flowBranch: FlowBranchEnum;
  username: string;
  errorCode: string;
}

export enum FlowBranchEnum {
  login,
  create,
  password,
  username,
  sso,
  profile,
  changeusername
}

