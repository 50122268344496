<!-- Sign In scenario  -->
<ng-container *ngIf="loginStore.flowBranch === flowBranchEnum.login && loginStore.errorCode !== 'UE05'">
    <ng-container *ngIf="loginStart && !loginStore.errorCode"
      ssTrackEvent [event]="track.loginUsernameInputPageView"></ng-container>
    <h1 class="text-title-4 mb-8" translate>common.signin</h1>
    <p class="mb-24 text-body-lg">{{headerHolder | translate}}</p>
    <div class="mb-16" *ngIf="loginStore.errorCode">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorCode()"
        role="alert"
        ssTrackError [error]="track.usernameInputErrors"
      >
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="exclamation-circle"
              color="error"
              [ariaHidden]="false"
              caption="Attention"
              [attr.title]="'rogers.login.accessibility.error' | translate"
            ></ds-icon>
            <div>

              <p *ngIf='loginStore.errorCode === "UE01"; else otherErrors'
                  class="text-body mb-0 mw-content">
                  <span *ngIf="(client !== 'r4b' && client !== 'media')">
                    <span *ngIf='currentLang === "en"'>
                    {{ 'rogers.errors.' + loginStore.errorCode | translate }}
                        <a href="javascript:void(0)"
                        variant="cta"
                        class="error-link mb-0 ds-color-link"
                        [attr.title]="'common.accessibility.forgotUsername' | translate"
                        (click)="routeLink(flowBranchEnum.username)"
                      >{{
                      'rogers.errors.UE011' | translate
                      }}</a>
                          {{ 'rogers.errors.UE012' | translate }}
                    </span>

                    <span *ngIf='currentLang === "fr"'>
                      {{ 'rogers.errors.' + loginStore.errorCode | translate }}
                        <a href="javascript:void(0)"
                        variant="cta"
                        class="error-link mb-0 ds-color-link"
                        [attr.title]="'common.accessibility.forgotUsername' | translate"
                        (click)="routeLink(flowBranchEnum.username)"
                      >{{
                      'rogers.errors.UE011' | translate
                      }}</a>
                    </span>
                  </span>

                  <span *ngIf='client === "r4b"'>{{ 'rogers.errors.UE013' | translate }}</span>
                  <span *ngIf='client === "media"'>{{ 'rogers.errors.UE014' | translate }}
                    <a
                    variant="cta"
                    class="ds-color-link"
                    (click)="onRegisterClickSubmit()"
                    [attr.title]="client + '.login.registerAttr' | translate">{{footerRegister|translate}}</a>
                  </span>

              </p>
              <ng-template #otherErrors>
                <p class="text-body mb-0 mw-content"
                  [innerHTML]="'rogers.errors.' + loginStore.errorCode | translate"
                ></p>
              </ng-template>



            </div>
          </div>
        </div>
      </ds-alert>
    </div>
  <form [formGroup]="loginForm" (ngSubmit)="validateAndSubmit()" method="POST">
      <div>
        <ds-form-field>
          <ds-input-label id="username-label">
            {{ usernameLabel | translate}}
          </ds-input-label>
          <input
            dsInput
            type="email"
            formControlName="username"
            #username
            (keydown.enter)="keyDownEnter($event)"
            (keydown.space)="$event.preventDefault()"
            autocomplete="username"
            [attr.autocorrect]="'off'"
            spellcheck="false"
            autocapitalize="off"
            autofill
            [attr.aria-required]="true"
            [attr.aria-invalid]="false"
            attr.title="{{ usernameAttr | translate}}"
            attr.aria-label="{{ usernameTitle | translate}}"
          />
          <ds-popover
            dsSuffix
            triggerLabel="{{ 'rogers.login.popOverOpen' | translate }}"
            closeLabel="{{ 'rogers.login.popOverClose' | translate }}"
            content="{{ loginTip | translate }}"
            title="“close”"
            closeCaption="{{ 'rogers.login.popOverClose' | translate }}"
            triggerCaption="{{ 'rogers.login.popOverOpen' | translate }}"
          >
          </ds-popover>
          <ds-error
            errorMessage="{{ 'rogers.errors.ECA1' | translate }}"
            alt="Attention"
            role="alert"
            *ngIf="submitted && loginForm.get('username').errors
                && loginForm.get('username').errors.required"
            ssTrackError
            [error]="track.loginUsernameInputFormError"
          ></ds-error>
          <ds-error
            errorMessage="{{ 'rogers.errors.FE01' | translate }}"
            alt="Attention"
            *ngIf="submitted && loginForm.get('username').errors &&
            loginForm.get('username').errors.email"
            role="alert"
            ssTrackError
            [error]="track.loginUsernameInputFormError"
        ></ds-error>
        </ds-form-field>
      </div>
      <div *ngIf="!hideForgotUsername">
        <a 
          ds-link
          variant="cta"
          class="mt-4 ds-color-link"
          [attr.title]="'common.accessibility.forgotUsername' | translate"
          (click)="routeLink(flowBranchEnum.username)"
        >{{ 'common.forgotUsername' | translate }}</a
        >
      </div>
      <div class="mb-24">
        <ds-checkbox
          formControlName="rememberMe"
          class="text-normal"
          (keydown.enter)="keyDownEnter($event)"
          attr.title="{{ rememberMeAttr | translate }}">
          {{ rememberMeTitle | translate }}
        </ds-checkbox>
      </div>
      <!-- [disabled]="!this.loginForm.valid" -->
      <div class="text-center signInButton ">
        <button ds-button
                type="submit"
                #submitLogin
                role="button"
                class="w-100 mb-16"
                title="{{ accessibilityContinue | translate }}">
          <span *ngIf="!loginInProgress">{{'common.continue' | translate}}</span>
          <eas-loader class="eas-loader" *ngIf="loginInProgress"></eas-loader>
        </button>
      </div>
    </form>
    <div class="ds-border-bottom mb-24 mt-16 ds-brcolor-concrete"></div>
    <div class="footer">
      <h5 class="registerButton">
        {{ footerHolder | translate }}
        <a
          ds-link
          variant="cta"
          class="ds-color-link"
          (click)="onRegisterClickSubmit()"
          [attr.title]="client + '.login.registerAttr' | translate">
          {{footerRegister|translate}}</a>
      </h5>
    </div>
    </ng-container>


<!-- Sign In scenario with Error UE05 -->
<ng-container *ngIf="loginStore.flowBranch === flowBranchEnum.login && loginStore.errorCode === 'UE05'">

  <h1 class="text-title-4 text-semi mb-18">
    {{ 'rogers.registration.alreadyRegistered.header' | translate }}
  </h1>
  <p
    class="mb-32 text-body-lg"
    [innerHTML]="
      'rogers.registration.alreadyRegistered.body.description'
        | translate: { rememberMeUser: userEmail.toLowerCase() }
    "
    ssTrackError [error]="track.usernameInputErrors"
  ></p>

  <button ds-button role="button" class="w-100" 
  [attr.title]="'rogers.registration.alreadyRegistered.accessibility.verifyEmailBtn' | translate"
    (click)="onVerifyEmail()">
    <span *ngIf="!verifyEmailInProgress">{{
      'rogers.registration.alreadyRegistered.verifyBtn' | translate
    }}</span>
    <eas-loader class="eas-loader" *ngIf="verifyEmailInProgress"></eas-loader>
  </button>


</ng-container>




    <!-- Register scenario  -->
  <ng-container *ngIf="loginStore.flowBranch === flowBranchEnum.create">
    <h1 class="text-title-4 mb-8" translate>media.registration.registerByEmail.header</h1>
    <p class="text-body-lg mb-24" translate>media.registration.registerByEmail.body</p>
    <div class="mb-16" *ngIf="loginStore.errorCode">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorCode()"
        role="alert"
      >
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="exclamation-circle"
              color="error"
              [ariaHidden]="false"
              caption="Attention"
              [attr.title]="'rogers.login.accessibility.error' | translate"
            ></ds-icon>
            <div>
              <p
                class="text-body mb-0 mw-content"
                [innerHTML]="'rogers.errors.' + loginStore.errorCode | translate"
              ></p>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="validateAndSubmit()"  method="POST" novalidate>
    <div class="mt-16 mb-32">
      <ds-form-field>
        <ds-input-label>{{
          'rogers.registration.registerByEmail.email' | translate
          }}</ds-input-label>
        <input
          dsInput
          type="email"
          [attr.autocorrect]="'off'"
          spellcheck="false"
          autocapitalize="off"
          formControlName="email"
          (keydown.space)="$event.preventDefault()"
          (keydown.enter)="keyDownEnter($event)"
          [attr.aria-required]="true"
          [attr.aria-label]="
          'rogers.registration.registerByEmail.accessibility.email'
            | translate
        "
          [attr.title]="
          'rogers.registration.registerByEmail.accessibility.email'
            | translate
        "
          #emailCreate
        />
        <ds-error
          [errorMessage]="'rogers.registration.registerByEmail.error.enterEmail' | translate"
          *ngIf="registerForm.get('email').errors &&
          registerForm.get('email').errors.required"
          role="alert"
          class="mt-8"
        ></ds-error>
        <ds-error
          [errorMessage]="'rogers.registration.registerByEmail.error.validEmail' | translate"
          *ngIf="registerForm.get('email').errors &&
          registerForm.get('email').errors.pattern"
          role="alert"
          class="mt-8"
        ></ds-error>
      </ds-form-field>
    </div>
    <div>
      <button ds-button
              type="submit"
              #submitCreate
              [disabled]="!this.registerForm.valid"
              role="button"
              class="w-100 mb-16"
              title="{{ 'common.accessibility.continue' | translate }}">
        {{'common.continue' | translate}}
      </button>
      <button ds-button
              variant="secondary"
              type="button"
              class="w-100 mb-48"
              [attr.title]="'common.accessibility.back' | translate"
              (click)="routeLink(flowBranchEnum.login)"
      >
        {{ 'common.back' | translate }}
      </button>
    </div>
    </form>
    <!-- <div class="ds-border-bottom mb-24 mt-16 ds-brcolor-concrete"></div>
    <div class="d-flex flex-wrap mb-40">
      <p
        class="pt-12 mr-4"
        [innerHTML]="
      'rogers.registration.registerByEmail.prepaid.msg' | translate
    "
      ></p>
      <button ds-button
              (click)="onPrepaid()"
              variant="tertiary"
              [attr.aria-label]="'rogers.registration.registerByEmail.accessibility.prepaid' | translate"
      >
        {{ 'rogers.registration.registerByEmail.prepaid.cta' | translate }}
        <ds-icon name="chevron-right"></ds-icon>
      </button>
    </div> -->
  </ng-container>

  <!-- Forgot username scenario - email  -->
  <ng-container *ngIf="loginStore.flowBranch === flowBranchEnum.username">
    <ng-container
      ssTrackEvent
      [event]="track.findUsernameWithEmailPageView"
    ></ng-container>
    <h1 class="text-title-4 mb-8" translate>{{ 'rogers.forgotUsername.header' }}</h1>
    <p class="mb-24 text-body-lg" translate>{{ forgotUsernameByEmailDesc }}</p>
    <div class="mb-16" *ngIf="loginStore.errorCode">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorCode()"
        role="alert"
      >
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="exclamation-circle"
              color="error"
              [ariaHidden]="false"
              caption="Attention"
              [attr.title]="'rogers.login.accessibility.error' | translate"
            ></ds-icon>

            <div>

              <p *ngIf='loginStore.errorCode === "UE01"; else otherErrors'
                  class="text-body mb-0 mw-content"
                  [innerHTML]="
                    'rogers.forgotUsername.error.UE01'
                        | translate: { email: userEmail }
                    "
                 ssTrackError [error]="track.usernameInputErrors"
                >

              </p>
              <ng-template #otherErrors>
                <p class="text-body mb-0 mw-content"
                  [innerHTML]="'rogers.errors.' + loginStore.errorCode | translate"
                   ssTrackError [error]="track.usernameInputErrors"
                ></p>
              </ng-template>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>
    <form [formGroup]="emailForm" (ngSubmit)="validateAndSubmit()"  method="POST" novalidate>
      <div>
        <ds-form-field>
          <ds-input-label>
            {{'rogers.forgotUsername.email' | translate}}
          </ds-input-label>
          <input
            dsInput
            type="email"
            [attr.autocorrect]="'off'"
            spellcheck="false"
            autocapitalize="off"
            formControlName="email"
            (keydown.space)="$event.preventDefault()"
            (keydown.enter)="keyDownEnter($event)"
            [attr.aria-required]="true"
            [attr.aria-invalid]="false"
            [attr.aria-label]="'rogers.forgotUsername.accessibility.EmailLabel' | translate"
            [attr.title]="'rogers.forgotUsername.accessibility.EmailLabel' | translate"
            #emailUsername/>

            <ds-popover
            dsSuffix
            triggerLabel="{{ 'rogers.login.popOverOpen' | translate }}"
            closeLabel="{{ 'rogers.login.popOverClose' | translate }}"
            content="{{ recoverUsernameTip| translate }}"
            title="“close”"
            closeCaption="{{ 'rogers.login.popOverClose' | translate }}"
            triggerCaption="{{ 'rogers.login.popOverOpen' | translate }}"
          >
          </ds-popover>

          <ds-error
          *ngIf="submitted && emailForm.invalid"
          errorMessage="{{ 'rogers.forgotUsername.error.validEmail' | translate}}"
          alt="Attention"
          role="alert"
          ssTrackError
          [error]="track.recoveryUsernameInputFormError">
        </ds-error>
        </ds-form-field>
        <p class="text-body-lg mb-32 mt-16">
          {{ 'rogers.forgotUsername.doNotRemember' | translate }}
          <a href="javascript: void(0)" (click)="onForgotUsernameAccountClickSubmit()"
             [attr.aria-label]="'rogers.forgotUsername.accessibility.accountInformationLink' | translate"
             title="{{ 'rogers.forgotUsername.accessibility.accountInformationLink' | translate }}"
          >{{
            'rogers.forgotUsername.accountInfo' | translate
            }}</a>
        </p>
      </div>
      <div>
        <button ds-button
                type="submit"
                #submitUsername
                role="button"
                class="w-100 mb-16"
                title="{{ 'rogers.forgotUsername.accessibility.continue' | translate }}">
          {{'common.continue' | translate}}
        </button>
        <button ds-button
                type="button"
                class="w-100 mb-96" variant="secondary"
                (click)="routeLink(flowBranchEnum.login)"
                [attr.aria-label]="'common.back' | translate"
                title="{{ 'rogers.forgotUsername.accessibility.back' | translate }}">
          {{ 'common.back' | translate }}
          <eas-loader class="eas-loader" *ngIf="usernameInProgress"></eas-loader>
        </button>
      </div>
    </form>
  </ng-container>

  <!-- Forgot username scenario - account  -->
  <ng-container *ngIf="loginStore.flowBranch === flowBranchEnum.profile">
    <h1 class="text-title-4 mb-8" translate>rogers.registration.verifyIdentity.header</h1>
    <p class="mb-8 text-body-lg" translate>rogers.registration.verifyIdentity.description</p>
    <div class="mb-16" *ngIf="loginStore.errorCode">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorCode()"
        role="alert"
      >
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="exclamation-circle"
              color="error"
              [ariaHidden]="false"
              caption="Attention"
              [attr.title]="'rogers.login.accessibility.error' | translate"
            ></ds-icon>
            <div>
              <p
                class="text-body mb-0 mw-content"
                [innerHTML]="'rogers.errors.' + loginStore.errorCode | translate"
              ></p>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>
    <form [formGroup]="accountForm" (ngSubmit)="validateAndSubmit()"  method="POST" novalidate>
      <div class="row">
        <div class="col-xs-12 col-sm-6 mt-16">
          <ds-form-field>
            <ds-input-label>{{
              'rogers.forgotUsername.accountNumber' | translate
              }}</ds-input-label>
            <input
              dsInput
              #account
              type="text"
              formControlName="accountNumber"
              [dsMask]="inputMaskType.Custom"
              [customMask]="{ mask: accountNumberMask, placeholderChar: ' ' }"
              [attr.aria-required]="true"
              [attr.aria-invalid]="false"
              [attr.aria-label]="
                'rogers.forgotUsername.accessibility.accountNumber' | translate
              "
              [attr.title]="
                'rogers.forgotUsername.accessibility.accountNumber' | translate
              "
            />
            <ds-error
              [errorMessage]="
                'rogers.forgotUsername.error.validAccount' | translate
              "
              *ngIf="accountForm.invalid &&
              accountForm.get('accountNumber').invalid"
              role="alert"
            ></ds-error>
          </ds-form-field>
          <div class="mt-4 text-semi">
            <a
              href="javascript: void(0)"
              (click)="openModal(whereToFindModal)"
              [attr.aria-label]="
                'rogers.forgotUsername.accessibility.findLink' | translate
              "
              [attr.title]="
                'rogers.forgotUsername.accessibility.findLink' | translate
              "
            >
              {{ 'rogers.forgotUsername.whereToFind' | translate }}
            </a>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 mt-16">
          <ds-form-field>
            <ds-input-label>{{
              'rogers.forgotUsername.postalCode' | translate
              }}</ds-input-label>
            <input
              dsInput
              type="text"
              formControlName="postalCode"
              [customMask]="{ mask: 'a0a0a0', placeholderChar: ' ' }"
              [dsMask]="inputMaskType.Custom"
              [attr.aria-required]="true"
              [attr.aria-invalid]="false"
              [attr.aria-label]="
                'rogers.forgotUsername.accessibility.postalCode' | translate
              "
              [attr.title]="
                'rogers.forgotUsername.accessibility.postalCode' | translate
              "
            />
            <ds-error
              [errorMessage]="
                'rogers.forgotUsername.error.validPostalCode' | translate
              "
              *ngIf="accountForm.invalid &&
              accountForm.get('postalCode').invalid"
              role="alert"
            ></ds-error>
          </ds-form-field>
        </div>
      </div>
      <div class="mt-16 mb-32">
        <ds-form-field>
          <ds-input-label>{{
            'rogers.forgotUsername.dob' | translate
            }}</ds-input-label>
          <input
            dsInput
            [dsMask]="inputMaskType.Custom"
            [customMask]="dobMask"
            type="text"
            formControlName="dob"
            [attr.aria-required]="true"
            [attr.aria-invalid]="false"
            [attr.aria-label]="
            'rogers.forgotUsername.accessibility.dob' | translate
          "
            [attr.title]="'rogers.forgotUsername.accessibility.dob' | translate"
          />
          <ds-error
            [errorMessage]="'rogers.forgotUsername.error.validDob' | translate"
            *ngIf="accountForm.invalid &&
             accountForm.get('dob').invalid"
            role="alert"
          ></ds-error>
        </ds-form-field>
      </div>
      <div>
        <button ds-button
                type="submit"
                #submitProfile
                [disabled]="!this.accountForm.valid"
                role="button"
                class="w-100 mb-16"
                title="{{ 'common.accessibility.continue' | translate }}">
          {{'common.continue' | translate}}
        </button>
        <button ds-button
                type="button"
                class="w-100 mb-96" variant="secondary"
                (click)="routeLink(flowBranchEnum.username)"
                [attr.aria-label]="'common.back' | translate"
                title="{{ 'common.back' | translate }}"
        >
          {{ 'common.back' | translate }}
        </button>
      </div>
    </form>
    <ng-template #whereToFindModal>
      <ds-modal
        [heading]="'rogers.forgotUsername.whereToFindModal.header' | translate"
        [closeCaption]="'rogers.forgotUsername.accessibility.closeAlt' | translate"
        class="find-account-number-modal"
        size="large-adaptive"
        (closeClick)="modalCloseButtonClick($event)"
      >
        <div body>
          <div class="text-body-lg">
            {{ 'rogers.forgotUsername.whereToFindModal.description' | translate }}
          </div>
          <!--TODO: after closeAll functionality gets added to ds-accordion we should switch to that.-->
          <ds-accordion>
            <ds-accordion-panel [data]="accountModalAccordion[0]">
              <div class="mb-16">
                {{
                'rogers.forgotUsername.whereToFindModal.billIsReadyBody' | translate
                }}
              </div>
              <ds-picture
                [src]="'./idp/__default/assets/images/bill-' + currentLang + '.png'"
                [alt]="'rogers.forgotUsername.whereToFindModal.imgMonthlyBillAlt' | translate"
                ratio="auto"
              ></ds-picture>
            </ds-accordion-panel>
            <ds-accordion-panel [data]="accountModalAccordion[1]">
              <div class="mb-16">
                {{
                'rogers.forgotUsername.whereToFindModal.serviceAgreementBody'
                  | translate
                }}
              </div>
              <ds-picture
                [src]="'./idp/__default/assets/images/service-agreement-' + currentLang + '.png'"
                [alt]="'rogers.forgotUsername.whereToFindModal.imgServiceAgreementAlt' | translate"
                ratio="auto"
              ></ds-picture>
            </ds-accordion-panel>
          </ds-accordion>

          <div class="d-flex justify-content-end close-modal">
            <button
              class="mt-32"
              ds-button
              (click)="closeModal()"
              [attr.title]="
            'rogers.forgotUsername.accessibility.modalCoseBtn' | translate
          "
            >
              {{ 'rogers.forgotUsername.whereToFindModal.close' | translate }}
            </button>
          </div>
        </div>
      </ds-modal>
    </ng-template>
  </ng-container>

  <!-- <ng-container *ngIf="loginStore.flowBranch === flowBranchEnum.changeusername"> -->
    <ng-template #changeusernameModal>
      <ds-modal
      class="full-screen-change-username w-100 w-sm-75 w-md-50 m-sm-auto m-sm-12 border ds-bgcolor-snow dam-maxheight"
      size="full-width-adaptive"
      (closeClick)="closeModal()"
    >
      <div body class="pr-sm-56 pl-sm-56 pb-sm-32">
        <app-change-username (back)="closeModal()"></app-change-username>
        </div>
      </ds-modal>
    </ng-template>
  <!-- </ng-container> -->


  <div [class]="recaptchaClass" *ngIf="!blockRecaptcha" (outsideClick)="outsideClickCapture()">
    <re-captcha
      *ngIf="useRecaptchaV3"
      #captchaRefV3="reCaptcha"
      [siteKey]="reCaptchaV3SiteKey"
      size="invisible"
      [badge]="recaptchaBadge"
      [actionName]="'username'"
      (resolved)="$event && processRecaptcha($event)"
    ></re-captcha>
    <re-captcha
      *ngIf="!useRecaptchaV3"
      #captchaRef="reCaptcha"
      [siteKey]="reCaptchaSiteKey"
      size="invisible"
      [badge]="recaptchaBadge"
      (resolved)="$event && processRecaptcha($event)">
    </re-captcha>
  </div>