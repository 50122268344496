import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputMaskType } from '@rogers/cdk/form';
import { ModalRef } from '@rogers/cdk/modal';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { GlobalStateService } from '../shared/global-state.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-change-recovery-number',
  templateUrl: './change-recovery-number.component.html',
  styleUrls: ['./change-recovery-number.component.scss']
})
export class ChangeRecoveryNumberComponent implements OnInit, AfterViewInit {

  phoneNumberForm: UntypedFormGroup;
  modalRef: ModalRef;
  @Input() showLogo = true;
  isFormSubmitted = false;
  setRecoveryNumberInProgress = false;
  readonly InputMaskType = InputMaskType;
  flowFromPayload: string;
  logo: string;
  url: string = '';
  state: string = '';
  error = '';
  isIframe: boolean;
  errorMessage = "";
  setPhoneNumbermessage: string;


  @ViewChild('usernameInputPhoneNumber') usernameInputPhoneNumber: ElementRef<HTMLInputElement>;
  @ViewChild('continueBtn') continueBtn: ElementRef<HTMLElement>;

  onSubmitNewRecoveryNumber: (payload: object) => void;
  onSkipRecoveryNumberClick: () => void;

  track = {
    changeMobileInputPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-mobile-input',
    },
    changeMobileInputErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-mobile-errors',
      category: 'self serve',
      type: '',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    changeMobileInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-mobile-errors',
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: 'sms recovery number'
    },
  };

  constructor(private readonly configurationService: ConfigurationService,
              private readonly globalStateService: GlobalStateService,
              private readonly translate: TranslateService,
              private readonly cookieService: CookieService) { }

  ngOnInit(): void {
    var element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    const phoneNumberRegex = '^\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$';
    this.globalStateService.setHideLangToggle(false);
    this.phoneNumberForm = new UntypedFormGroup({
      phoneNumber: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(phoneNumberRegex)
      ])
    });

    this.setTranslatePropertiesByBrand();
    this.isIframe = false;


    if (window.self !== window.top) {
        this.isIframe = true;
    }

    if (window.location !== window.parent.location) {
      this.isIframe = true;
    }

    if (this.isIframe)
    {
      console.log('Load change-recover-number in Iframe');
    }

  }

  ngAfterViewInit() {
    setTimeout(_ => {
      this.usernameInputPhoneNumber.nativeElement.focus();
    }, 50);
  }

  closeModal() {
    // this.modalRef.close();
    console.log('Recovery Phone Number Skipped');
    this.url = this.cookieService.get('redirect_uri');

    this.state = this.cookieService.get('state');

    let appendChar = '?';
    if (this.url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = this.url + appendChar + 'error=' + 'ctnupdateskip'
    + '&error_description=ctnupdateskip'
    + '&lang=' + this.cookieService.get('lang')
    + '&state=' + this.state;
  }

  onSubmit() {
    this.isFormSubmitted = true;

    const mobile = this.phoneNumberForm.value.phoneNumber;
    const ctn = this.phoneNumberForm.value.phoneNumber.replace(/[^\d]/g, '');

    if (mobile === '' || mobile === null || mobile === undefined || mobile === '(___) ___-____'){
       this.errorMessage = 'rogers.setPhoneNumber.errors.validPhoneNumber';
       return;
    }
    // phone number valid lenfth is 10 numeric + 4 chars formatting
    else if (ctn.length < 10){
      this.errorMessage = 'rogers.setPhoneNumber.errors.invalidPhone';   
      return;
    }

    if (this.phoneNumberForm.invalid) {
      this.usernameInputPhoneNumber.nativeElement.focus();
      return;
    }
    else{
      this.setRecoveryNumberInProgress = true;
      this.cookieService.set('newRecoveryNumber', this.phoneNumberForm.value.phoneNumber,undefined, undefined, null, true, 'None');

      // const ctn = this.phoneNumberForm.value.phoneNumber.replace(/[^\d]/g, '');
      this.onSubmitNewRecoveryNumber({
        action: 'submit',
        number: ctn,
        flow: 'changeRecoveryNumber',
      });
    }
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    this.continueBtn.nativeElement.click();
  }

  onRecoveryNumberChangeSkip(event) {
    event.preventDefault();
    this.onSkipRecoveryNumberClick();

  }

  setErrorCode(errCode) {
    this.error = errCode;
  }

  resetErrorMessage() {
    this.error = '';
  }

  get isLogoLinked() {
    return false;
  }

  get homeLinkUrl() {
    const homeURL = this.configurationService.config.homeURL;
    return homeURL + `?language=${this.translate.currentLang}`;
  }

  get urlTarget() {
    return '_self';
  }

  private setTranslatePropertiesByBrand(){
    const client = this.cookieService.get('client');
    if (client === 'media'){
    }
    else if (client === 'fido') {
      this.logo = './idp/__default/assets/images/f-bg-1.png';
    }
    else if (client === 'r4b') {
      if (this.translate.currentLang === 'fr')
      {
        this.logo = './idp/__default/assets/images/r4blogo_fr.png';
      }
      else
      {
        this.logo = './idp/__default/assets/images/r4blogo_en.png';
      }
    }
    else if (client === 'yahoo') {
      this.logo = './idp/__default/assets/images/yahoo.png';
    }
    else {
      this.logo = './idp/__default/assets/images/og-image.png';
    }

    // Client specific translation
    if (client === 'media'){
      this.setPhoneNumbermessage = 'media.setPhoneNumber.message';
    }
    else if (client === 'fido') {
      this.setPhoneNumbermessage = 'fido.setPhoneNumber.message';
    }
    else if (client === 'r4b') {
      this.setPhoneNumbermessage = 'r4b.setPhoneNumber.message';
    }
    else if (client === 'yahoo') {
      this.setPhoneNumbermessage = 'yahoo.setPhoneNumber.message';
    }
    else {
      this.setPhoneNumbermessage = 'rogers.setPhoneNumber.message';
    }
  }
}
