import { commonEnvironment } from './common-environment';

export const environment = {
  ...commonEnvironment,
  production: false,
  //Specify timeout in seconds
  sessionTimeout: 1800,
  dynatraceEnv: 'qa',
  // recaptchaSiteKey: '6LfMqMwcAAAAAAcYS7a1mwFoA4icQyVejEtAtWNE',
  // recaptchaV3SiteKey: ' 6LdCqM4cAAAAAJishD2nwsswUl7_6T-UzVZakCBH',
  rogersV3SiteKey: '6LdCqM4cAAAAAJishD2nwsswUl7_6T-UzVZakCBH',
  fidoV3SiteKey: '6LcXvc0cAAAAADATI12sfotwqy8viPwMc5igH8rj',
  // yahooV3SiteKey: '6LcZyHIjAAAAAOaWgeAZTxDSvUt3ABu7aL7nkHVi',
  yahooV3SiteKey: '6Ld0fj0oAAAAAHdm18PtwykU_IpGPxgpU8TL4VWg',
  chatrV3SiteKey: '6Ld1ix8oAAAAANqo5s-H5sZv2xiDPtYNc0hx4Z8S',
  mediaV3SiteKey: '6LcuXCAoAAAAAIA51CvoprJXhWTLtgWwz-XkurlK',
  r4bV3SiteKey: '6LeaVyAoAAAAAE5MoVI_W2uUK-LvZmPbSOHjHyhc',
  // chV3SiteKey: '6Ld23mAkAAAAAC4fXDJW9wnkVHCOgABhf61HuQzx',

  rogersV2SiteKey: '6LfMqMwcAAAAAAcYS7a1mwFoA4icQyVejEtAtWNE',
  fidoV2SiteKey: '6Lf-d80cAAAAAAXfi8CfVa4oPcDOblqUk0_WJnA_',
  // yahooV2SiteKey: '6LffIJQjAAAAAMCFgESxmgtC761w8jDOGociHbfK',
  yahooV2SiteKey: '6LcXMz8oAAAAAG93eBhIqZMTdFPdN2nivMq33pUY',
  chatrV2SiteKey: '6Lced0IoAAAAAEa619zgkeMwFPkU_NDbt77ZPOF9',
  mediaV2SiteKey: '6LfWfUIoAAAAAA9QU07zptp7WKF1_6wHK2n7WAiW',
  r4bV2SiteKey: '6LeSgUIoAAAAABzKMwd0WXLp-N9lhb9AFf4bsUfz',
  // chV2SiteKey: '6LezT2MkAAAAAM7CFwy-OBg0TdVN2gIrBiLE7H8M',
  
  analyticsAPIUrl: 'https://rogers-dev.transmit.security/api/v2/auth/assert',
  analyticsUrl: 'https://assets.adobedtm.com/8cf1b6f99d16/6668466f6850/launch-EN7b9c4a0353944c2e8fa7c97e9a08224b-staging.min.js',
  analyticsUrlR4B: 'https://assets.adobedtm.com/8cf1b6f99d16/5119ce42e2ec/launch-9206614d64df-staging.min.js',
  analyticsUrlFido: 'https://assets.adobedtm.com/8cf1b6f99d16/c73a1c919337/launch-ENe5414305fc53414387b48ce7d88fc281-staging.min.js',  
  analyticsUrlChatr: 'https://assets.adobedtm.com/8cf1b6f99d16/5ba693524426/launch-d41654fa91ba-staging.min.js',  
  homeURL: 'https://qa7.rogers.com/',
  fidohomeURL: 'https://qa7.fido.ca/',
  yahoohomeURL: 'https://www.rogers.com/',
  r4bhomeURL: 'https://qa13.rogers.com/mbh/',
  chatrhomeURL: 'https://chatr-qa-auto.qa01.eks.rogers.com/',
  liveChatServiceConfig: {
    accountId: '369303105785976989',
    websiteId: '1975063211994161751'
  },
};
