import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from "@angular/core";
import { ChangeRecoveryNumberComponent } from "./change-recovery-number.component";

class ChangeRecoveryNumberFormSession implements com.ts.mobile.sdk.UIFormSession{
    clientContext: object;
    actionContext: object;
    formId: string;
    payload: object;

    submitHandler: any;
    submitBlock: (payload: object) => void;
    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    changeRecoveryNumberComponentRef: ComponentRef<ChangeRecoveryNumberComponent>;

    constructor(formId: string, payload: object) {
        this.formId = formId;
        this.payload = payload;
    }

    startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
        this.clientContext = clientContext;
        this.actionContext = actionContext;
        this.resolver = (clientContext as any).resolver;
        this.viewContainerRef = (clientContext as any).viewContainerRef;
      }

      endSession(): void {
        console.log('Change recovery number Form session ended: ' + this.formId);
      }

      promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
        const self = this;
        self.viewContainerRef.clear();
        const factory: ComponentFactory<ChangeRecoveryNumberComponent> =
         self.resolver.resolveComponentFactory(ChangeRecoveryNumberComponent);
        self.changeRecoveryNumberComponentRef = self.viewContainerRef.createComponent(factory);
        self.changeRecoveryNumberComponentRef.instance.onSubmitNewRecoveryNumber = self.onSubmitReoveryNumber.bind(self);
        self.changeRecoveryNumberComponentRef.instance.onSkipRecoveryNumberClick = self.onSkipRecoveryNumberClick.bind(self);


        console.log('change recoveryNumberTs constructor payload:', this.payload);

        const journeyPayload = this.payload as JourneyPayload;

        const action = journeyPayload.actions[0] as ActionsInPayload;
        console.log('change recoveryNumberTs action flow using interface', action.flow);

        self.changeRecoveryNumberComponentRef.instance.flowFromPayload = 'changeUsername';

        return new Promise((resolve, reject) => {
          self.submitBlock = (payload: object) => {
            try {
              resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
              console.log('change recoveryNumberTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
              self.submitBlock = null;
            } catch (e) {
              console.log('change recoveryNumberTs createFormInputSubmissionRequest ERROR payload: ', payload);
              console.error(e);
            }
          };
        });
      }

      onContinue(payload: object): void {
        console.log('change recoveryNumberTs onContinue for ' + this.formId + ' payload:', payload);
      }

      onError(payload: object): void {
        if (payload){
          const errorCode = payload['code'];
          setTimeout(() => this.changeRecoveryNumberComponentRef.instance.setErrorCode(errorCode), 100);
        }

        console.log('change recoveryNumberTs onError for ' + this.formId + ' payload:', payload);
        // const errorPayload = payload as JourneyPayload;

      }

      private onSubmitReoveryNumber(payload: object) {
        this.clientContext['nextStep'] = 'changeRecoveryNumberFlow';
        const data = payload;
        this.submitBlock(data);
      }

      private onSkipRecoveryNumberClick(){
        const url = this.clientContext['redirect_uri'];
        const state = this.clientContext['state'];
        const errorCode  = 'ctnupdateskip';
        let appendChar = '?';
        if (url.includes('?'))
        {
           appendChar = '&';
        }
        window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=ctnupdateskip' 
        + '&lang=' + this.clientContext['cookieService'].get('lang')
        + '&state=' + state;
      }

}

interface JourneyPayload {
    actions: ActionsInPayload[];
    stat: string;
    code: string;
    flow: string;
  }

interface ActionsInPayload {
    [key: string]: any;
  }

export default ChangeRecoveryNumberFormSession;
