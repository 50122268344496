import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PasswordHint } from '@rogers/cdk/form';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { GlobalStateService } from 'src/app/shared/global-state.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss']
})
export class SetPasswordFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private setPasswordValueChanges;
  private newPasswordValue = '';
  private confirmPasswordValue = '';
  setPasswordForm: UntypedFormGroup;
  hideNewPassword = true;
  hideConfirmPassword = true;
  hidePasswordTip = true;
  hideConfirmPasswordTip = true;
  username: string;
  @ViewChild('setNewPassswordRef')
  setNewPassswordRef: ElementRef;
  @ViewChild('newPasswordRef')
  newPassswordRef: ElementRef;
  passwordHintsCaps = false;
  blankPasswordSubmit = false;
  isNewPassordValid = true;
  confirmPwdError = false;

  @ViewChild('confirmPasswordRef') confirmPasswordRef: ElementRef;


  onResetPasswordSubmit: (code: string) => void;

  newPasswordHints: Array<PasswordHint> = [
    {
      type: 'message',
      message: 'rogers.setPassword.hint.oneLetter'
    },
    {
      type: 'message',
      message: 'rogers.setPassword.hint.oneNumber'
    },
    {
      type: 'message',
      message: 'rogers.setPassword.hint.minimumEight'
    },
    {
      type: 'message',
      message: 'rogers.setPassword.hint.allowedCharacters',
      isVisible: false
    },
    {
      type: 'fnKey',
      message: 'rogers.setPassword.hint.capsLockOn',
      icon: 'capslock',
      isVisible: false
    }
  ];
  confirmHints: Array<PasswordHint> = [
    {
      type: 'message',
      message: 'rogers.setPassword.message.passwordMatch'
    },
    {
      type: 'fnKey',
      message: 'rogers.setPassword.hint.capsLockOn',
      icon: 'capslock',
      isVisible: false
    }
  ];

  callInProgress = false;
  apiErrorTranslationKey: string;
  passwordValidationPattern: RegExp;
  passwordValidationPatternWithoutLength: RegExp;

  track = {
    setPasswordPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'set-password',
    },
    setPasswordApiErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'set-password',
      category: 'self serve',
      type: 'http',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    setPasswordSigninAttempt: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'set-password-signin-attempt',
    },
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly configurationService: ConfigurationService,
    private readonly globalStateService: GlobalStateService,
    private readonly translateService: TranslateService,
    private readonly titleService: Title,
    private readonly cookieService: CookieService

  ) {
    this.passwordValidationPattern =
    configurationService.config.passwordValidationPattern;
    this.passwordValidationPatternWithoutLength =
    configurationService.config.passwordValidationPatternWithoutLength;
  }
  ngOnInit() {
    this.createForm();
    this.username = this.cookieService.get('userEmail');
    console.log('username is set password form : ', this.username);
    this.globalStateService.setHideLangToggle(false);

    const client = this.cookieService.get('brand');
    const mediaClient = this.cookieService.get('client');

    this.titleService.setTitle(
      this.translateService.instant(client + '.setPassword.browserTitle')
    );
if (mediaClient === 'media'){
  this.titleService.setTitle(
    this.translateService.instant(mediaClient + '.setPassword.browserTitle')
  );
}

  }

  ngAfterViewInit() {
     setTimeout(_ => {
      this.newPassswordRef.nativeElement.focus();
    }, 5);
  }

   // Create a new form for set password
  createForm() {
    this.setPasswordForm = this.formBuilder.group(
      {
        userName: new UntypedFormControl(this.username),
        newPassword: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.passwordValidationPattern)
        ]),
        confirmPassword: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.passwordValidationPattern)
        ])
      },
      {
        validator: this.validateSetPasswordForm.bind(this)
      }
    );
    this.setPasswordValueChanges = this.setPasswordForm.valueChanges.subscribe(
      formControlList => this.setPasswordFormOnChange(formControlList)
    );
  }

  ngOnDestroy() {
    this.setPasswordValueChanges.unsubscribe();
  }

  setPasswordFormOnChange(ctrls) {
    if (!ctrls.hasOwnProperty('newPassword')) {
      return;
    }
    this.newPasswordValue = ctrls.newPassword;
    this.confirmPasswordValue = ctrls.confirmPassword;

    this.passwordChange(null);
  }

  passwordChange(key) {
    if (this.newPasswordValue.match(/[^0-9A-Za-z!@#$]/g)) {
      this.newPasswordHints[3].isVisible = true;
      this.newPasswordHints[3].valid = false;
    } else {
      this.newPasswordHints[3].isVisible = false;
    }

    this.newPasswordHints[0].valid = this.newPasswordValue.match(/[a-zA-Z]/)
      ? true
      : key;
    this.newPasswordHints[1].valid = this.newPasswordValue.match(/[0-9]/)
      ? true
      : key;
    this.newPasswordHints[2].valid =
      this.newPasswordValue.length >= 8 ? true : key;

    if (
      this.confirmPasswordValue === this.newPasswordValue &&
      this.confirmPasswordValue.length >= 1
    ) {
      this.confirmHints[0].valid = true;
      this.setPasswordForm.controls.confirmPassword.setErrors(null);
    } else {
      this.confirmHints[0].valid = key;
      this.setPasswordForm.controls.confirmPassword.setErrors({
        valid: false
      });
    }

    const pwd = this.setPasswordForm.controls.newPassword.value;
    const confirmPwd = this.setPasswordForm.controls.confirmPassword.value;

    if (pwd === '' && confirmPwd === ''){
      this.isNewPassordValid = true;
    }
   else if (!this.newPasswordHints[0].valid || !this.newPasswordHints[1].valid
      && !this.newPasswordHints[2].valid || !this.newPasswordHints[4].valid){
        this.isNewPassordValid = false;
    }
    else{
      this.isNewPassordValid = true;
    }

  }

  validateSetPasswordForm(formGroup: UntypedFormGroup) {
    let validationFlag = false;
    if (
      formGroup &&
      formGroup.contains('newPassword') &&
      formGroup.contains('confirmPassword')
    ) {
      if (
        formGroup.controls.newPassword.value ===
        formGroup.controls.confirmPassword.value
      ) {
        validationFlag = true;
      }
    }
    return validationFlag
      ? null
      : {
          validateForm: {
            valid: false
          }
        };
  }

  onSetPasswordSubmit(){
    if (this.setPasswordForm.invalid)
    {
      if (this.hideConfirmPasswordTip) { this.hideConfirmPasswordTip = false; }
      this.passwordChange(false);
      const errorField = this.setNewPassswordRef.nativeElement.querySelectorAll(
        'ds-form-field.-error input'
      );

      if ((this.newPasswordValue === '' || this.newPasswordValue === undefined )
      && (this.confirmPasswordValue === '' ||  this.confirmPasswordValue === undefined)){
        this.blankPasswordSubmit = true;
      }

      if (this.setPasswordForm.controls.newPassword.valid &&
         (this.confirmPasswordValue === '' ||  this.confirmPasswordValue === undefined)){
        this.confirmPasswordRef.nativeElement.focus();
        this.confirmPwdError = true;
      }
      else{
        this.confirmPwdError = false;
        this.blankPasswordSubmit = true;
      }
      return;
    }
    this.callInProgress = true;
    console.log(this.newPasswordValue);
    console.log(this.confirmPasswordValue);
    this.onResetPasswordSubmit(this.newPasswordValue);
  }

  checkOnKeyChange(event: KeyboardEvent, type: string): void {
    if ((this.newPasswordValue === '' || this.newPasswordValue === undefined )
    && (this.confirmPasswordValue === '' ||  this.confirmPasswordValue === undefined)){
      this.blankPasswordSubmit = true;
    }
    else{
      this.blankPasswordSubmit = false;
    }
    this.passwordHintsCaps =
      event.getModifierState && event.getModifierState('CapsLock');
    if (type === 'new') {
        this.newPasswordHints[4].isVisible = this.passwordHintsCaps;
      }
    if (type === 'confirm') {
        this.confirmHints[1].isVisible = this.passwordHintsCaps;
      }
  }

}
