import { Inject, Injectable } from '@angular/core';

import {
  AnalyticsError,
  AnalyticsService,
  ICompleteUniversalDataLayer,
  InteractionEvent,
  Mapping
} from '@rogers/analytics';
import {
  SelfServeAnalyticsService,
  TrackErrorData,
  TrackEventData
} from '@rogers/self-serve-analytics';
import { cloneDeep, get, has } from 'lodash';
import { clientMapping, flowMapping } from './analytics-mapping-config';
import { AnalyticsConfiguration } from './analytics-configuration';
import { ANALYTICS_CONFIGURATION } from './analytics-configuration.service';
import { modalPopup } from './global-analytics-configuration';
import { Router } from '@angular/router';
import { GlobalStateService } from '../global-state.service';
import { CookieService } from 'ngx-cookie-service';

export function provideSelfServeAnalyticsService() {
  return [
    AnalyticsServiceProxy,
    {
      provide: SelfServeAnalyticsService,
      useClass: AnalyticsServiceProxy
    }
  ];
}

@Injectable()
export class AnalyticsServiceProxy implements SelfServeAnalyticsService {

  constructor(
    @Inject(ANALYTICS_CONFIGURATION)
    private readonly analyticsConfiguration: AnalyticsConfiguration,
    private readonly analyticsService: AnalyticsService,
    private readonly globalStateService: GlobalStateService,
    private readonly cookieService: CookieService,
    private readonly router: Router
  ) {
    this.mappings = this.analyticsConfiguration.mappings;
    this.callBackFunctions = this.analyticsConfiguration.callBackFunctions;

    this.url = this.router.url.replace('/', '');
  }
  private readonly mappings: Mapping;
  private readonly callBackFunctions: {
    [name: string]: Function;
  };
  private readonly url: string;

  private static getFlowHierarchy(payload, event, cookieService) {
        const title = cookieService.get('title');
    const flow = cookieService.get('flow');
    let flowType: string;
    if (
      payload &&
      payload.page &&
      payload.page.hierarchy &&
      event &&
      (event.mappingKey.indexOf('otp') === 0 ||
        event.mappingKey.indexOf('set-password') === 0)
    ) {
      if (title) {
        flowType = title;
        payload.page.hierarchy.push(`${flowMapping[flowType]}`);
        if (payload.selfServe && payload.events) {
          if (
            (flowType === 'forgotusername' || flowType === 'forgotpassword') &&
            event.mappingKey.indexOf('otp-method-selection') === 0
          ) {
            payload.selfServe.type = 'transaction';
            payload.selfServe.name = `${flowMapping[flowType]}`
              .split('-').filter(i => i !== '-').join(' ');
            payload.events.selfServeStart = true;
          }
        }
      } else {
        if (
          flow === 'create' &&
          event.mappingKey.indexOf('set-password') === 0
        ) {
          /* commented out as per jira AAIO-3223 
          flowType = 'autoreg';
          payload.selfServe.name = `${flowMapping[flowType][1]}`
            .split('-').filter(i => i !== '-').join(' ');
          payload.events.selfServeStart = true;
          */
        }
      }
    }
    return payload;
  }

  private static getSignInType(isSigninTracking, cookieService) {
    if (!isSigninTracking) { return; }
    
    // Brand
    let brand = cookieService.get('brand');
    console.log('brand is ', brand);

    const igniteBrand = cookieService.get('igniteBrand');
    console.log('igniteBrand is ', igniteBrand);

    // we need seperate analytics signin type for the below brands
    // do not change the brand to rogers here
    if (igniteBrand === 'rshm' || igniteBrand === 'xfi' || igniteBrand === 'ignitetv'){
      brand = igniteBrand;
    }
    //console.log('signinType is ', this.getMapping(brand, clientMapping)['signinType'])
    return this.getMapping(brand, clientMapping)['signinType'];  }



  static getMapping = (brandName: string, obj: any) => {
    if (!obj) {
      return;
    }
    let hostFilter = location.hostname.split('.')
      .filter(
        i =>
          i === 'rogers' ||
          i === 'fido' ||
          i === 'chatrwireless'
      ).join('');
    const path = location.pathname;
    if (path === '/business') {
      hostFilter = 'r4b';
    }
    if (obj[brandName]) {
      return obj[brandName];
    } else if (obj[hostFilter]) {
      return obj[hostFilter];
    } else {
      console.warn('analytics signin type set to OTHER for ignitebrand : ', brandName);
      return obj['other'];

    }
  };

  track(
    _payload: ICompleteUniversalDataLayer,
    isModalPopup: boolean,
    _selectedSections,
    mapping
  ) {
    try {
      const payload = cloneDeep(_payload);
      if (payload && payload.page && isModalPopup) {
        payload.page.url = modalPopup;
      }
      const callbackName = get(mapping, 'callbackName');
      if (!!callbackName && has(this.callBackFunctions, callbackName)) {
        this.callBackFunctions[callbackName](payload);
      }
      if (!!get(payload, 'errors')) {
        this.analyticsService.trackErrors([] as AnalyticsError[], payload);
      } else {
        const isPageView = get(payload, 'events.pageView');
        if (isPageView) {
          this.analyticsService.trackPageLoad(payload, false);
        } else {
          this.analyticsService.trackClick({} as InteractionEvent, payload);
        }
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.warn('Exception in track', e);
    }
  }

  trackEvent(event: TrackEventData, errors?: AnalyticsError[]) {
    if (!event) {
      return;
    }
    try {
      let payload: ICompleteUniversalDataLayer = cloneDeep(
        this.mappings[event.mappingKey]
      );
      payload = AnalyticsServiceProxy.getFlowHierarchy(payload, event, this.cookieService);
      if (
        event.callBackParam &&
        has(this.callBackFunctions, event.callBackParam)
      ) {
        this.callBackFunctions[event.callBackParam](payload);
      }
      if (event.pageView) {
        if (!payload.events) {
          payload.events = {};
        }
        payload.events.pageView = true;
      }
      if (payload && payload.page && event.isModalPopup) {
        payload.page.url = modalPopup;
      }
      if (event.additionalPayload) {
        if (event.additionalPayload.errors) {
          payload.errors = event.additionalPayload.errors;
          if (!payload.events) {
            payload.events = {};
          }
          payload.events.error = true;
        } else if (event.additionalPayload.miscellaneous) {
          payload.miscellaneous  = event.additionalPayload.miscellaneous;
          if (!payload.events) {
            payload.events = {};
          }
          payload.events.miscellaneous = true;
        }
      }
      const isPageView = get(payload, 'events.pageView');
      const signSuccuss = get(payload, 'events.signinSuccess');
      const signinattempts = get(payload, 'events.signinAttempt');
      if (signinattempts || signSuccuss) {
        const signin = signinattempts || signSuccuss;
        payload.signin = {
          type: AnalyticsServiceProxy.getSignInType(signin,this.cookieService)
        };
      }
      if (isPageView) {
        this.analyticsService.trackPageLoad(payload, false);
      } else {
        this.analyticsService.trackClick({} as InteractionEvent, payload);
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.warn('Exception in dataLayer track event directive', err);
    }
  }

  trackError(errorObject: TrackErrorData, errorMessage: string) {
    const {
      code,
      api,
      API,
      flowType,
      field,
      mappingKey,
      category,
      callBackParam,
      type
    } = errorObject;
    if (
      api === undefined &&
      API === undefined &&
      code === undefined &&
      field === undefined &&
      category === undefined &&
      type === undefined &&
      flowType === undefined
    ) {
      return;
    }
    try {
      const payload: AnalyticsError[] = [
        {
          API: !!api ? api : API,
          code,
          field,
          type,
          category,
          message: errorMessage,
          flowType: flowType || ''
        }
      ];
      let globalVars = mappingKey ? cloneDeep(this.mappings[mappingKey]) : {};
      globalVars = AnalyticsServiceProxy.getFlowHierarchy(globalVars, errorObject, this.cookieService);
      if (callBackParam) {
        this.callBackFunctions[callBackParam](globalVars);
      }
      if (errorObject.pageView) {
        if (!globalVars.events) {
          globalVars.events = {};
        }
        globalVars.events.pageView = true;
      }
      this.analyticsService.trackErrors(payload, globalVars);
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.warn('Exception in dataLayer track error directive', err);
    }
  }

}
