<ng-container>
    <h1 class="text-title-4 mb-8" translate>media.registration.registerByEmail.header</h1>
    <p class="text-body-lg mb-24" translate>media.registration.registerByEmail.body</p>
    <div class="mb-16" *ngIf="errorCode !== ''">

      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorCode()"
        role="alert"
      >
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="exclamation-circle"
              color="error"
              [ariaHidden]="false"
              caption="Attention"
              [attr.title]="'rogers.login.accessibility.error' | translate"
            ></ds-icon>
            <div>
              <p *ngIf='errorCode === "ECP3"; else otherErrors'
                class="text-body mb-0 mw-content"
              >
                 {{ 'rogers.errors.' + errorCode | translate }}
                   <a href="javascript:void(0)"
                   variant="cta"
                   class="error-link mb-0 ds-color-link"
                   (click)="signInClick()"
                 >Sign In</a>

            </p>
              <ng-template #otherErrors>
                <p class="text-body mb-0 mw-content"
                innerHTML="{{ errorMesageCode | translate }}"
                ></p>
              </ng-template>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="validateAndSubmit()"  method="POST" novalidate>
    <div class="mt-16 mb-32">
      <ds-form-field>
        <ds-input-label>{{
          'rogers.registration.registerByEmail.email' | translate
          }}</ds-input-label>
        <input
          dsInput
          type="email"
          [attr.autocorrect]="'off'"
          spellcheck="false"
          autocapitalize="off"
          formControlName="email"
          (keydown.space)="$event.preventDefault()"
          (keydown.enter)="keyDownEnter($event)"
          [attr.aria-required]="true"
          [attr.aria-label]="
          'rogers.registration.registerByEmail.accessibility.email'
            | translate
        "
          [attr.title]="
          'rogers.registration.registerByEmail.accessibility.email'
            | translate
        "
          #emailCreate
        />
        <ds-error
          [errorMessage]="'rogers.registration.registerByEmail.error.enterEmail' | translate"
          *ngIf="registerForm.invalid  && !invalidEmail &&
          registerForm.get('email').errors.required"
          role="alert"
          class="mt-8"
        ></ds-error>
        <ds-error
          [errorMessage]="'rogers.registration.registerByEmail.error.validEmail' | translate"
          *ngIf="registerForm.invalid && invalidEmail"
          role="alert"
          class="mt-8"
        ></ds-error>
      </ds-form-field>
      <p class="text-body-lg mb-24 mt-16">
        {{'media.terms' | translate}} 
        <a [href]="getPolicyLink()" target="_blank">
          {{'media.privacy' | translate}}</a> 
        {{'media.and' | translate}} 
        <a href="https://www.rogerssportsandmedia.com/terms-of-service/" target="_blank">
          {{'media.conditions' | translate}}</a> 
        {{'media.policy' | translate}}
      </p>
    </div>
    <div>
      <button ds-button
              type="submit"
              #submitCreate
              role="button"
              class="w-100 mb-16"
              title="{{ 'common.accessibility.continue' | translate }}">
              <span *ngIf="!registerInProgress">{{'common.continue' | translate}}</span>
              <eas-loader class="eas-loader" *ngIf="registerInProgress"></eas-loader>
      </button>
      <button ds-button
              variant="secondary"
              type="button"
              class="w-100 mb-48"
              [attr.title]="'common.accessibility.back' | translate"
              (click)="onRegisterskip($event)"
      >
        {{ 'common.back' | translate }}
      </button>
    </div>
    </form>
  </ng-container>

  <div [class]="recaptchaClass" *ngIf="!blockRecaptcha" (outsideClick)="outsideClickCapture()">
    <re-captcha
      *ngIf="useRecaptchaV3"
      #captchaRefV3="reCaptcha"
      [siteKey]="reCaptchaV3SiteKey"
      size="invisible"
      [badge]="recaptchaBadge"
      [actionName]="'username'"
      (resolved)="$event && processRecaptcha($event)"
    ></re-captcha>
    <re-captcha
      *ngIf="!useRecaptchaV3"
      #captchaRef="reCaptcha"
      [siteKey]="reCaptchaSiteKey"
      size="invisible"
      [badge]="recaptchaBadge"
      (resolved)="$event && processRecaptcha($event)">
    </re-captcha>
  </div>
