import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { LoginStoreModel } from '../shared/login-store.model';
import isEmpty from 'lodash/isEmpty';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';

@Component({

    templateUrl: './passwordFormSession.component.html',
    styleUrls: ['./passwordFormSession.component.scss']

})
export class PasswordFormSessionComponent implements OnInit, AfterViewInit {
  private readonly windowObj: any;
  code = '';
  username: string;
  onSubmitCode: (code: string) => void;
  onForgotPassword: () => void;
  onChangeUsernameClick: () => void;
  errCode: string;

  passwordStore = new PasswordStoreModel();
  readonly flowBranchEnum = FlowBranchEnum;

  @ViewChild('loginFormRef', { static: true }) loginFormRef: ElementRef;
  loginForm = new UntypedFormGroup(
    {
      username: new UntypedFormControl(''),
      input_password: new UntypedFormControl('', [Validators.required])
    },
    { updateOn: 'submit' }
  );

  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('loginBtn') loginBtn: ElementRef;
  formSubmitted = false;
  loginInProgress = false;
  accountLocked = false;
  showNewPassword = true;
  loginStart = false;
  passwordFormError = false;
  headerHolder: string;
  trackPageView = false;
  signinAttr: string;
  client: string | null;

  changeUsernameTitle: string;
  changeUsernameAttr: string;


  loginFlow$: BehaviorSubject<LoginStoreModel>;

  track = {
    loginPasswordInputPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'password-input'
    },
    loginSigninAttempt: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'signin-attempt',
    },
    loginPasswordApiErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'password-input',
      category: 'login',
      type: 'http',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    loginPasswordInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'password-input',
      category: 'login',
      type: 'form',
      code: 'ECA3',
      API: '',
      field: 'password'
    },
  };

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private readonly cookieService: CookieService
  ) {
    this.windowObj = !!window.frameElement
      ? (window.parent as any)
      : (window as any);

    this.passwordStore.flowBranch = this.flowBranchEnum.login;
  }

  ngOnInit() {
    this.loginForm.controls['username'].setValue(this.username);

    this.changeUsernameTitle = 'rogers.login.back';
    this.changeUsernameAttr = 'rogers.login.changeUserName';

    this.client  = this.cookieService.get('client');
    if (this.client === 'media'){
      this.headerHolder = 'media.login.header';
      this.signinAttr = 'media.login.signinAttr';
      this.changeUsernameTitle = 'media.login.back';
      this.changeUsernameAttr = 'media.login.changeUserName';

    }
    else if (this.client === 'fido') {
      this.headerHolder = 'fido.login.header';
      this.signinAttr = 'fido.login.signinAttr';

    }
    else if (this.client === 'r4b') {
      this.headerHolder = 'r4b.login.header';
      this.signinAttr = 'r4b.login.signinAttr';

    }
    else if (this.client === 'yahoo') {
      this.headerHolder = 'yahoo.login.header';
      this.signinAttr = 'yahoo.login.signinAttr';

    }
    else if (this.client === 'cpp' || this.client === 'chatr') {
      this.headerHolder = 'chatr.login.header';
      this.signinAttr = 'chatr.login.signinAttr';

    }
    else {
      this.headerHolder = 'rogers.login.header';
      this.signinAttr = 'rogers.login.signinAttr';

    }

    this.passwordStore.errorCode = this.cookieService.get('loginStoreErrorCode');
    this.cookieService.set('loginStoreErrorCode', '',undefined, undefined, null, true, 'None');

    // this.initForm();
    if (!this.cookieService.get('analytics')) {
      this.trackPageView = true;
      this.cookieService.set('analytics', 'passwordStarted',undefined, undefined, null, true, 'None');
    }

    this.translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((langEvent: LangChangeEvent) => {
        this.titleService.setTitle(
          this.translate.instant(this.client + '.login.browserTitle')
        );
      });
    setTimeout(() => {
      this.titleService.setTitle(
        this.translate.instant(this.client + '.login.browserTitle')
      );
    }, 500);

    // Browser back button functionality
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = function(event) {
        window.location.reload();
        // console.log('Back button clicked. Triggered from passwordformsession component ');
    };
  }

  validateAndSubmit() {
    if (this.accountLocked){
      this.refreshPage();
    }

    this.resetErrorMessage();
    if (isEmpty(this.code)) {
      this.passwordFormError = true;
    }
    else
    {
      this.passwordFormError = false;
    }
    if (this.passwordFormError) {
      return;
    }

    this.loginInProgress = true;
    this.onSubmitCode(this.code);

    console.log(`PasswordFormSession - OnSubmit:${this.code}`);
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    this.loginBtn.nativeElement.click();
  }

  registerUser() {}

  initForm() {
    setTimeout(_ => {
      this.passwordInput.nativeElement.focus();
    }, 500);
  }


  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.passwordInput.nativeElement.focus();
    }, 500);
  }


  setErrorCode(code){
    this.loginInProgress = false;
    this.resetErrorMessage();
    this.passwordStore.errorCode = code;
    if (code === 'UE03'){
      this.errCode = 'rogers.errors.UE031';
    }
    else if (code === 'UE04'){
      // uncomment to show error in SPA
      // this.accountLocked = true;
      console.log('UE04 Error');
    }
    else{
      this.errCode = '';
      this.accountLocked = false;
    }

    const pwd = this.cookieService.get('errorPwdCode');

    this.loginForm.controls['input_password'].setValue(pwd);
    this.setAnalyticsError(code);
    this.cookieService.delete('errorPwdCode');
  }

  resetErrorMessage() {
    this.passwordStore.errorCode = '';

    if (this.accountLocked)
    {
      this.refreshPage();
    }
  }

  routeLink(link) {
    this.resetErrorMessage();
    if (link === 'changeUsername'){
      // window.location.reload();
      this.onChangeUsernameClick();
    }
  }

  onForgotPasswordClick(){
    this.cookieService.set('title', 'forgotpassword',undefined, undefined, null, true, 'None');
    this.onForgotPassword();
  }

  refreshPage()
  {
    window.location.reload();
  }

  setAnalyticsError(code: string) {
    if (this.track && this.track.loginPasswordApiErrors && code) {
      this.track.loginPasswordApiErrors.code = code;
      if (code === 'UE05') {
        this.track.loginPasswordApiErrors.pageView = true;
        this.track.loginPasswordApiErrors.mappingKey = 'email-already-registered';
        this.track.loginPasswordApiErrors.type = 'user';
      } else if (code.indexOf('SE') >= 0) {
        this.track.loginPasswordApiErrors.type = 'http';
      } else if (code.indexOf('UE') >= 0) {
        this.track.loginPasswordApiErrors.type = 'user';
      }
    }
  }

  ngOnDestroy(): void {}
}

export class PasswordStoreModel {
  flowBranch: FlowBranchEnum;
  username: string;
  errorCode: string;
}

export enum FlowBranchEnum {
  login,
  create,
  password,
  username,
  sso,
  profile,
  error,
  forgotPassword
}
