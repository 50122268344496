import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { stat } from 'fs';
import { RegisteFormSessionComponent } from './register.component';

class RegisterFormSession implements com.ts.mobile.sdk.UIFormSession{
  clientContext: object;
  actionContext: object;
  formId: string;
  payload: object;

  submitHandler: any;
  submitBlock: (payload: object) => void;
  // currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  registeFormSessionComponentRef: ComponentRef<RegisteFormSessionComponent>;

  constructor(formId: string, payload: object) {
      console.log('Inside registerFormTs constructor');
      this.formId = formId;
      this.payload = payload;
  }
  startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
    console.log('registerFormTs session started: ' + this.formId);
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.resolver = (clientContext as any).resolver;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

  }
  endSession(): void {
    console.log('registerFormTs session ended: ' + this.formId);
    console.log('User locked');
  }
  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
    console.log('registerFormTs session Input: ' + this.formId);
    const self = this;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<RegisteFormSessionComponent> = self.resolver.resolveComponentFactory(RegisteFormSessionComponent);
    self.registeFormSessionComponentRef = self.viewContainerRef.createComponent(factory);

    self.registeFormSessionComponentRef.instance.onSubmitUserEmail = self.onSubmitEmailRegister.bind(self);
    self.registeFormSessionComponentRef.instance.onRegisterskipClick = self.onRegisterSkip.bind(self);
    self.registeFormSessionComponentRef.instance.onSignInClick = self.onSignInClick.bind(self);

    console.log('registerFormTs constructor payload:', this.payload);

    const journeyPayload = this.payload as JourneyPayload;
    console.log('registerFormTs:payload actions using interface', journeyPayload.actions);


    return new Promise((resolve, reject) => {
      self.submitBlock = (payload: object) => {
        try {
          resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
          console.log('registerFormTs SUCCESS payload: ', payload);
          self.submitBlock = null; // assign null to prevent using the same promise more then once
        } catch (e) {
          console.log('registerFormTs ERROR payload: ', payload);
          console.error(e);
        }
      };
    });
  }
  onContinue(payload: object): void {
    console.log('registerFormTs onContinue for ' + this.formId + ' payload:', payload);
  }
  onError(payload: object): void {
    console.log('registerFormTs onError for ' + this.formId + ' payload:', payload);
    const errorPayload = payload as JourneyPayload;
    const errorCode = errorPayload.code;

    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];

    if (errorCode === 'UE01') { // user not recognized
      this.clientContext['cookieService'].set('registerErrorCode', errorCode ? errorCode : null, undefined, undefined, null, true, 'None');
    }
    else if ((errorCode === '0' || 'UE05') && errorPayload.stat === 'ok'){ // user already exists(verified) and user already registered
      this.clientContext['cookieService'].set('registerErrorCode', 'ECP3', undefined, undefined, null, true, 'None');
    }
    else if (errorPayload.code === 'RC01'){
      this.processRecaptchaFailure();
  }
}
   /** Non UIFormSession interface Functions */
  private onSubmitEmailRegister(payload: object) {
    console.log('registerFormTs  submit for ' + this.formId + ' payload:', payload);
    const data = payload;
    this.clientContext['cookieService'].set('registerUsername', data['username'], undefined, undefined, null, true, 'None');
    this.submitBlock(data);
  }

  private onRegisterSkip(){
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'cancel_register';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=cancel_register'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

  private onSignInClick(){
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'signIn';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=signIn'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

  private processRecaptchaFailure(){
    const self = this;
    this.clientContext['cookieService'].set('registerUseRecaptchaV3', false, undefined, undefined, null, true, 'None');
    this.clientContext['cookieService'].set('registerReCaptchaVersion', 'V2', undefined, undefined, null, true, 'None');

    setTimeout(() => self.registeFormSessionComponentRef.instance.validateAndSubmit(), 10);
  }
}

interface JourneyPayload {
  actions: ActionsInPayload[];
  stat: string;
  code: string;
  flow: string;
  recaptchastatus: string;
}

interface ActionsInPayload {
  [key: string]: any;
}

export default RegisterFormSession;