import { Component, OnInit } from '@angular/core';
import { CheckboxChange } from '@rogers/cdk/form';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-update-multi-factor-auth',
  templateUrl: './update-multi-factor-auth.component.html',
  styleUrls: ['./update-multi-factor-auth.component.scss']
})
export class UpdateMultiFactorAuthComponent implements OnInit {

  emailStatus = '';
  numberStatus = '';
  email  = '';
  number = '';

  none = 'rogers.auth.none';

  enableEmail = false;
  enableNumber =  false;

  enabledFactors = null;
  disabledFactors = null;

  emailChecked = false;
  numberChecked =  false;

  emailDisabled = false;
  numberdisabled = false;

  diableSaveChanges = true;

  initialEmailStatus = false;
  initialNumberStatus = false;

  numberText = '';
  emailText = '';

  currentEmailStatus =  false;
  currentNumberStatus = false;

  onMFAProfileSubmit: (payload: object) => void;
  onSaveMfaChanges = false;

  profileBody: string;
  profileEmail: string;
  profileNumber: string;

  showEmailOption = true;


  track = {
    selectAuthenticatonFactorsPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'modal-select-authenticaton factors',
      additionalPayload: {
        miscellaneous: {
          type: 'multi-factor authentication',
          name: '',
        }
      }
    },
    modalUpdateMFASuccess: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'modal-update MFA success',
      additionalPayload: {
        miscellaneous: {
          type: 'multi-factor authentication',
          name: '',
        }
      }
    },
  };

  constructor(private readonly configurationService: ConfigurationService, private readonly cookieService:CookieService) { }

  ngOnInit(): void {

    this.initialEmailStatus = this.emailStatus === 'enabled' ? true : false;
    this.initialNumberStatus = this.numberStatus === 'enabled' ? true : false;

    this.emailText = this.initialEmailStatus ? 'rogers.auth.accessibility.DisableAlt' : 'rogers.auth.accessibility.enableAlt';
    this.numberText = this.initialNumberStatus ? 'rogers.auth.accessibility.DisableAlt' : 'rogers.auth.accessibility.enableAlt';

    this.emailDisabled = (this.email === '' || this.email === undefined || this.email === null || this.email == 'null') ? true : false;
    this.numberdisabled = (this.number === '' || this.number === undefined || this.number === null || this.number == 'null') ? true : false;

    this.emailChecked = this.enableEmail = this.emailStatus === 'enabled' ? true : false;
    this.numberChecked = this.enableNumber = this.numberStatus === 'enabled' ? true : false;

    this.emailStatus = this.emailStatus === 'enabled' ? 'rogers.auth.enabled' :  'rogers.auth.disabled';
    this.numberStatus = this.numberStatus === 'enabled' ? 'rogers.auth.enabled' :  'rogers.auth.disabled';

    this.currentEmailStatus = this.initialEmailStatus;
    this.currentNumberStatus = this.initialNumberStatus;

    if (this.emailDisabled) {
      this.emailChecked = false; 
      this.emailStatus = 'rogers.auth.disabled';
      this.emailText = 'rogers.auth.accessibility.DisableAlt';
    }

    if (this.numberdisabled) {
      this.numberChecked = false;
      this.numberStatus = 'rogers.auth.disabled';
      this.numberText = 'rogers.auth.accessibility.DisableAlt';
    }

    // set up mfa analytics on init

    const email = this.emailChecked ? 'email' : null;
    const mobile = this.numberChecked ? 'mobile' : null;

    if (this.emailChecked &&  this.numberChecked){
        this.track.selectAuthenticatonFactorsPageview.additionalPayload.miscellaneous.name
        = 'Recovery Number - on | user name - on';
    
      }
    else if (this.emailChecked){
      this.track.selectAuthenticatonFactorsPageview.additionalPayload.miscellaneous.name
      = 'Recovery Number - off | user name - on';

    }
    else if (this.numberChecked){
      this.track.selectAuthenticatonFactorsPageview.additionalPayload.miscellaneous.name
      = 'Recovery Number - on | user name - off';
    }
    else{      
      this.track.selectAuthenticatonFactorsPageview.additionalPayload.miscellaneous.name
      = 'Recovery Number - off | user name - off';
    }

    //

    this.setTranslatePropertiesByBrand();

    const client = this.cookieService.get('client');
    if (client === 'yahoo'){
      this.showEmailOption = false;
    }

  }

  onEmailToggle(event: CheckboxChange) {
    const status = event.checked;
    this.currentEmailStatus = status;
    this.emailText = status ? 'rogers.auth.accessibility.DisableAlt' : 'rogers.auth.accessibility.enableAlt';


    if (this.currentNumberStatus !== this.initialNumberStatus){
      this.diableSaveChanges = false;
    }

    else if (status === this.initialEmailStatus){

      this.diableSaveChanges = true;
    }
    else{
      this.diableSaveChanges = false;
    }

    this.emailStatus = status ? 'rogers.auth.enabled' : 'rogers.auth.disabled';
    this.enableEmail = status;

    if ((!this.currentNumberStatus || this.numberdisabled) && !this.currentEmailStatus){
      this.diableSaveChanges = true;
    }
  }

  onNumberToggle(event: CheckboxChange) {
    const status = event.checked;

    this.currentNumberStatus = status;
    this.numberText = status ? 'rogers.auth.accessibility.DisableAlt' : 'rogers.auth.accessibility.enableAlt';

    if (this.currentEmailStatus !== this.initialEmailStatus){
      this.diableSaveChanges = false;
    }
    else if (status === this.initialNumberStatus){
      this.diableSaveChanges = true;
    }
    else{
      this.diableSaveChanges = false;
    }

    this.numberStatus = status ? 'rogers.auth.enabled' : 'rogers.auth.disabled';
    this.enableNumber = status;

    if (!this.currentNumberStatus && (!this.currentEmailStatus || this.emailDisabled)){
      this.diableSaveChanges = true;
    }
    if(this.cookieService.get('client') === 'yahoo' && !this.currentNumberStatus ){
      this.diableSaveChanges = true;
    }
  }

  private setTranslatePropertiesByBrand(){
    const client = this.cookieService.get('client');

    this.profileBody = 'rogers.auth.profile.body';
    this.profileEmail = 'rogers.auth.profile.email';
    this.profileNumber = 'rogers.auth.profile.number';


    // Overrides below
    if (client === 'fido'){
      this.profileBody = 'fido.auth.profile.body';
      this.profileEmail = 'fido.auth.profile.email';
      this.profileNumber = 'fido.auth.profile.number';
    }

  }

  onSubmit(){
    const email = this.enableEmail ? 'email' : null;
    const mobile = this.enableNumber ? 'mobile' : null;

    if (email !== null){
      if (mobile !== null){
        this.enabledFactors = email + ',' + mobile;
        this.disabledFactors = null;
        this.track.modalUpdateMFASuccess.additionalPayload.miscellaneous.name
        = 'Recovery Number - on | user name - on';
    
      }
      else{
        this.disabledFactors = null;
        this.enabledFactors = email;
        this.track.modalUpdateMFASuccess.additionalPayload.miscellaneous.name
        = 'Recovery Number - off | user name - on';
    
      }
    }
    else if (mobile !== null){
      this.enabledFactors = 'mobile';
      this.disabledFactors =  null;
      this.track.modalUpdateMFASuccess.additionalPayload.miscellaneous.name
            = 'Recovery Number - on | user name - off';
    
    }
    else{
      this.disabledFactors =  'email' + ',' + 'mobile';
      this.enabledFactors = null;
      this.track.modalUpdateMFASuccess.additionalPayload.miscellaneous.name
            = 'Recovery Number - off | user name - off';
    
    }

    //send analytics event    
    this.onSaveMfaChanges = true;

    this.onMFAProfileSubmit({
      Enabled: this.enabledFactors,
      Disabled: this.disabledFactors,
      language: this.cookieService.get('lang')
    });
  }
}
